.header {
  justify-content: space-between;
  align-items: center;
  height: 9.6rem;
  padding: 0 2.8rem;
  display: flex;
  box-shadow: 0 0 8px 2.5px #a7a7a733;
}

.logo {
  height: 4.2rem;
}

.main-nav-list {
  align-items: center;
  gap: 4.8rem;
  list-style: none;
  display: flex;
}

.main-nav-link:link, .main-nav-link:visited {
  color: #5a5a5a;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .5s;
}

.main-nav-link:hover, .main-nav-link:active {
  color: #000;
}

.main-nav-link.nav-cta:link, .main-nav-link.nav-cta:visited {
  color: #fff;
  background-color: #e60000;
  border-radius: 11px;
  padding: 1.2rem 2rem;
  font-size: 2rem;
}

.main-nav-link.nav-cta:hover, .main-nav-link.nav-cta:active {
  background-color: #c00;
}

.section-hero {
  padding: 4.8rem 0;
}

.hero {
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 9.6rem;
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
}

.hero-description {
  margin-bottom: 2.8rem;
  font-size: 2rem;
  line-height: 1.5;
}

.hero-img {
  width: 100%;
}

.delivered-text {
  margin-top: 3rem;
  font-size: 2.7rem;
}

.delivered-text span {
  color: #000;
  font-weight: bold;
}

.section-featured {
  padding: 0 0 3.2rem;
}

.heading-featured-in {
  text-transform: uppercase;
  letter-spacing: .75px;
  text-align: center;
  color: #888;
  margin-bottom: 3.4rem;
  font-size: 2rem;
  font-weight: 500;
}

.logos {
  justify-content: space-between;
  display: flex;
}

.logos img {
  height: 5.2rem;
}

.section-how {
  padding: 9.6rem 0;
}

.step-number {
  color: #ddd;
  margin-bottom: 1.2rem;
  font-size: 9.8rem;
  font-weight: 600;
}

.step-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

.step-img {
  border-radius: 9px;
  width: 100%;
  box-shadow: 0 0 2px 2px #a7a7a733;
}

.section-services {
  padding-top: 0;
  padding-bottom: 9.6rem;
}

.service {
  border-radius: 11px;
  transition: all .4s;
  overflow: hidden;
  box-shadow: 0 2.4rem 4.8rem #00000013;
}

.service:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem #00000013;
}

.service-content {
  padding: 3.2rem 4.8rem 4.8rem;
}

.service-title {
  color: #333;
  margin-bottom: 3.2rem;
  font-size: 2rem;
  font-weight: 600;
}

.service-attributes {
  flex-direction: column;
  gap: 2rem;
  list-style: none;
  display: flex;
}

.service-attribute {
  align-items: center;
  gap: 1.6rem;
  font-size: 1.8rem;
  display: flex;
}

.service-img {
  width: 100%;
}

.margin-less {
  margin-bottom: 6.4rem !important;
}

.section-reviews {
  padding-top: 0;
  padding-bottom: 9.6rem;
}

.review-head {
  align-items: center;
  gap: .8rem;
  display: flex;
}

.review-img {
  width: 4.2rem;
  display: inline-block;
}

.review-title {
  font-size: 2.2rem;
  font-weight: 600;
  display: inline-block;
}

.review-text {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5;
}

.low-gap {
  margin-bottom: 6.4rem !important;
}

.rm-link {
  text-decoration: none;
}

.read-more {
  text-align: center;
  color: #e60000;
  font-size: 2rem;
  font-weight: 400;
  transform: translateY(-24px);
}

.read-more:hover {
  color: #c00;
  cursor: pointer;
  text-decoration: underline;
  box-shadow: 0 3.2rem 6.4rem #00000013;
}

.section-pricing {
  padding: 9.6rem 0;
}

.pricing-plan {
  border: 11px;
  border-radius: 9px;
  flex-direction: column;
  align-items: center;
  gap: 2.2rem;
  height: 100%;
  transition: all .5s;
  display: flex;
}

.pricing-plan:hover {
  transform: translate(0, -2.5rem);
}

.pricing-plan--basic {
  border: 2px solid #fdf2e9;
  justify-self: end;
  width: 100%;
  padding: 4.6rem;
}

.pricing-plan--advanced {
  background-color: #fdf2e9;
  padding: 4.8rem;
  position: relative;
  overflow: hidden;
}

.pricing-plan--advanced:after {
  content: "Best Value";
  color: #333;
  text-transform: uppercase;
  background-color: #ffd43b;
  padding: .8rem 8rem;
  font-size: 1.4rem;
  font-weight: 700;
  position: absolute;
  top: 7%;
  right: -21%;
  transform: rotate(45deg);
}

.pricing-plan--professional {
  border: 2px solid #fdf2e9;
  justify-self: start;
  padding: 4.6rem;
}

.plan-header {
  text-align: center;
}

.plan-name {
  color: #c00;
  text-transform: uppercase;
  letter-spacing: .75px;
  margin-bottom: 3.2rem;
  font-size: 2rem;
  font-weight: 600;
}

.plan-price {
  color: #333;
  margin-bottom: 1.6rem;
  font-size: 6.2rem;
  font-weight: 600;
}

.plan-price span {
  margin-right: .8rem;
  font-size: 3rem;
  font-weight: 500;
}

.plan-text {
  color: #6f6f6f;
  font-size: 1.6rem;
  line-height: 1.6;
}

.plan-sign-up {
  text-align: center;
  align-items: center;
  display: flex;
}

.plan-details {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.6;
}

.map-area {
  width: 100%;
  transition: all .3s;
}

.map-area:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem #00000013;
}

.border-bottom-md {
  border-bottom: 2px solid #8080807f;
  padding-bottom: 9.6rem;
}

.iframe {
  border: none;
  width: 700px;
  height: 600px;
}

.section-cta {
  margin-top: 6rem;
  padding: 0 0 12.8rem;
}

.cta {
  background-image: linear-gradient(to bottom right, #fdfbfb, #ebedee);
  border-radius: 11px;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 4rem;
  display: grid;
  overflow: hidden;
  box-shadow: 0 2.4rem 4.8rem #00000026;
}

.cta-text-box {
  color: #45260a;
  padding: 4.8rem 6.4rem 6.4rem;
}

.cta-text {
  margin-bottom: 4.8rem;
  font-size: 1.8rem;
  line-height: 1.8;
}

.cta .heading-secondary {
  color: #45260a;
  margin-bottom: 3.2rem;
}

.cta-img-box {
  background-image: url("session.06070b4e.webp");
  background-position: center;
  background-size: cover;
}

.cta-form {
  grid-template-columns: 1fr 1fr;
  gap: 2.4rem 3.2rem;
  display: grid;
}

.cta-form label {
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
}

.cta-form input, .cta-form select {
  color: inherit;
  background-color: #fff;
  border: none;
  border-radius: 9px;
  width: 100%;
  padding: 1.2rem;
  font-family: inherit;
  font-size: 1.8rem;
  box-shadow: 0 1px 2px #0000001a;
}

.cta-form input::placeholder {
  color: #aaa;
}

.cta-form button {
  cursor: pointer;
  border: none;
  font-family: inherit;
}

.cta :focus {
  outline: none;
  box-shadow: 0 0 0 .25rem #fdf2e980;
}

.footer {
  padding: 12.8rem, 0;
  background-color: #e3e3e3;
}

.grid-footer {
  grid-template-rows: 1.5fr 1.5fr;
  row-gap: 6.3rem;
  margin-bottom: 0;
}

.logo-footer {
  margin-top: 4rem;
  margin-bottom: 1rem;
  margin-left: -1rem;
}

.logo-col {
  flex-direction: column;
  display: flex;
}

.footer-logo {
  margin-bottom: 3.2rem;
  display: block;
}

.social-links {
  gap: .8rem;
  list-style: none;
  display: flex;
}

.social-icon {
  width: 2.4rem;
  height: 2.4rem;
}

.copyright {
  color: #767676;
  margin-top: auto;
  font-size: 1.4rem;
  line-height: 1.6;
}

.footer-heading {
  font-size: 1.8rem;
  font-weight: 500;
}

.contacts {
  font-size: 1.6rem;
  font-style: normal;
  line-height: 1.6;
}

.footer-nav {
  flex-direction: column;
  gap: 2.4rem;
  list-style: none;
  display: flex;
}

.footer-link:link, .footer-link:visited {
  color: #767676;
  transform: all .3s;
  font-size: 1.6rem;
  text-decoration: none;
}

.footer-link:hover, .footer-link:active {
  color: #555;
}

.youtube:hover, .youtube:active {
  color: red;
}

.instagram:hover, .instagram:active {
  color: #e4405f;
}

.facebook:hover, .facebook:active {
  color: #0a66c2;
}

.menuicon {
  display: none;
}

.menuiconbar {
  background-color: #000;
  width: 35px;
  height: 5px;
  margin: 6px 0;
}

.cont-plan {
  gap: 2rem;
  padding: 2.4rem;
}

/*# sourceMappingURL=index.e3e4b118.css.map */
