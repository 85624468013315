/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #fdf2e9; */
  padding: 0 2.8rem;
  height: 9.6rem;
  box-shadow: 0 0 8px 2.5px rgba(167, 167, 167, 0.2);
}

.logo {
  height: 4.2rem;
}

/* Navigation */
.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 4.8rem;
}

.main-nav-link:link,
.main-nav-link:visited {
  text-decoration: none;
  color: #5a5a5a;
  font-weight: 600;
  font-size: 1.8rem;
  transition: all 0.5s;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #000000;
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  background-color: #e60000;
  padding: 1.2rem 2rem;
  border-radius: 11px;
  color: white;
  font-size: 2rem;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: #cc0000;
}

/* Hero Section */
.section-hero {
  /* background-color: #fdf2e9; */
  padding: 4.8rem 0 4.8rem 0;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  display: grid;
  padding: 0 3.2rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 9.6rem;
}

.hero-description {
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 2.8rem;
}

.hero-img {
  width: 100%;
}

.delivered-text {
  font-size: 2.7rem;
  margin-top: 3rem;
}
.delivered-text span {
  font-weight: bold;
  color: #000000;
}

/* Featured In */

.section-featured {
  padding: 0 0 3.2rem 0;
}

.heading-featured-in {
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 3.4rem;
  color: #888;
}

.logos {
  display: flex;
  justify-content: space-between;
}

.logos img {
  height: 5.2rem;
}

/* How it Works (About Section) */
.section-how {
  padding: 9.6rem 0;
}

.step-number {
  font-size: 9.8rem;
  font-weight: 600;
  color: #ddd;
  margin-bottom: 1.2rem;
}

.step-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

.step-img {
  width: 100%;
  box-shadow: 0 0 2px 2px rgba(167, 167, 167, 0.2);
  border-radius: 9px;
}

/* Services Section */

.section-services {
  padding-bottom: 9.6rem;
  padding-top: 0;
}

.service {
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 11px;
  overflow: hidden;
  transition: all 0.4s;
}

.service:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.075);
}

.service-content {
  padding: 3.2rem 4.8rem 4.8rem 4.8rem;
}

.service-title {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 3.2rem;
}

.service-attributes {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.service-attribute {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.service-img {
  width: 100%;
}

.margin-less {
  margin-bottom: 6.4rem !important;
}

/* Reviews Section */
.section-reviews {
  padding-bottom: 9.6rem;
  padding-top: 0;
}

.review-head {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.review-img {
  width: 4.2rem;
  display: inline-block;
}

.review-title {
  display: inline-block;
  font-weight: 600;
  font-size: 2.2rem;
}

.review-text {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5;
}

.low-gap {
  margin-bottom: 6.4rem !important;
}

.rm-link {
  text-decoration: none;
}

.read-more {
  font-size: 2rem;
  text-align: center;
  transform: translateY(-24px);
  color: #e60000;
  font-weight: 400;
}

.read-more:hover {
  text-decoration: underline;
  color: #cc0000;
  cursor: pointer;
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.075);
}

/* Pricing Plans Section */
.section-pricing {
  padding: 9.6rem 0;
}

.pricing-plan {
  border: 11px;
  border-radius: 9px;
  /* width: 75%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.2rem;
  transition: all 0.5s;
}

.pricing-plan:hover {
  transform: translate(0, -2.5rem);
}
.pricing-plan--basic {
  justify-self: end;
  border: 2px solid #fdf2e9;
  padding: 4.6rem;
  /* padding-bottom: 0; */
  /* gap: 1.6rem; */
  width: 100%;
}

.pricing-plan--advanced {
  background-color: #fdf2e9;
  padding: 4.8rem;
  position: relative;
  overflow: hidden;
}

.pricing-plan--advanced::after {
  content: "Best Value";
  position: absolute;
  top: 7%;
  right: -21%;
  background-color: #ffd43b;
  color: #333;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0.8rem 8rem;
  transform: rotate(45deg);
}

.pricing-plan--professional {
  justify-self: start;
  border: 2px solid #fdf2e9;
  padding: 4.6rem;
  /* padding-bottom: 0; */
}

.plan-header {
  text-align: center;
}

.plan-name {
  color: #cc0000;
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.75;
  margin-bottom: 3.2rem;
}

.plan-price {
  font-size: 6.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.6rem;
}

.plan-price span {
  font-size: 3rem;
  font-weight: 500;
  margin-right: 0.8rem;
}

.plan-text {
  font-size: 1.6rem;
  line-height: 1.6;
  color: #6f6f6f;
}

.plan-sign-up {
  text-align: center;
  display: flex;
  align-items: center;
}

.plan-details {
  font-size: 1.6rem;
  line-height: 1.6;
  text-align: center;
}

/* Map Area */
.map-area {
  width: 100%;
  transition: all 0.3s;
}

.map-area:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.075);
}

.border-bottom-md {
  border-bottom: 2px solid #8080807f;
  padding-bottom: 9.6rem;
}

.iframe {
  width: 700px;
  height: 600px;
  border: none;
}
/* CTA Section */

.section-cta {
  padding: 0 0 12.8rem 0;
  margin-top: 6rem;
}

.cta {
  display: grid;
  grid-template-columns: 2fr 1fr;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;
  background-image: linear-gradient(to right bottom, #fdfbfb, #ebedee);
  /* background-image: linear-gradient(to right bottom, #e9e9e9, #c7c7c7); */
  /* background-image: linear-gradient(to right bottom, #eb3333, #e91919); */
  overflow: hidden;
  margin-bottom: 4rem;
}

.cta-text-box {
  color: #45260a;
  padding: 4.8rem 6.4rem 6.4rem 6.4rem;
}

.cta-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 4.8rem;
}

.cta .heading-secondary {
  color: #45260a;
  margin-bottom: 3.2rem;
}

.cta-img-box {
  background-image: url("../img/session.webp");
  background-size: cover;
  background-position: center;
}

.cta-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3.2rem;
  row-gap: 2.4rem;
}

.cta-form label {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}
.cta-form input,
.cta-form select {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  border: none;
  background-color: #ffffff;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  color: inherit;
}

.cta-form input::placeholder {
  color: #aaa;
}

.cta-form button {
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.cta *:focus {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(253, 242, 233, 0.5);
}

/**********************/
/* Footer */
/*********************/

.footer {
  padding: 12.8rem, 0;
  background-color: #e3e3e3;
}

.grid-footer {
  grid-template-rows: 1.5fr 1.5fr;
  row-gap: 6.3rem;
  /* border-top: 2px solid #000000; */
  margin-bottom: 0rem;
}

.logo-footer {
  margin-top: 4rem;
  margin-bottom: 1rem;
  margin-left: -1rem;
}

.logo-col {
  display: flex;
  flex-direction: column;
}

.footer-logo {
  display: block;
  margin-bottom: 3.2rem;
}

.social-links {
  list-style: none;
  display: flex;
  gap: 0.8rem;
}

.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.copyright {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #767676;
  margin-top: auto;
}

.footer-heading {
  font-size: 1.8rem;
  font-weight: 500;
}

.contacts {
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.6;
}

.footer-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1.6rem;
  color: #767676;
  transform: all 0.3s;
}

.footer-link:hover,
.footer-link:active {
  color: #555;
}

.youtube:hover,
.youtube:active {
  color: #ff0000;
}

.instagram:hover,
.instagram:active {
  color: #e4405f;
}

.facebook:hover,
.facebook:active {
  color: #0a66c2;
}

.menuicon {
  display: none;
}

.menuiconbar {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}

.cont-plan {
  padding: 0 1.2rem;
  gap: 2rem;
  padding: 2.4rem;
}
